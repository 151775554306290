import jwt_decode from "jwt-decode";
import { UserRole } from "../../../generated/graphql";
export interface decodedIdToken {
  "https://punct.co.il/company"?: {
    name: string;
    id: number;
    active: boolean;
    taxId: string;
  };
  name: string;
  picture: string;
  email: string;
  sub: string;
  "https://punct.co.il/roles": UserRole[];
}

const decodeIdToken = (idToken: string) => {
  try {
    const decoded = jwt_decode<decodedIdToken>(idToken);

    const {
      "https://punct.co.il/company": company,
      name,
      picture,
      email,
    } = decoded;
    const companyName = company?.name ?? "";
    const companyId = company?.id;

    return {
      companyId,
      companyName,
      name,
      userImage: picture,
      idTokenError: "",
      email,
      userId: decoded.sub,
      roles: decoded["https://punct.co.il/roles"],
    };
  } catch (error) {
    return { idTokenError: error as string };
  }
};

export { decodeIdToken };
