import { SuccessfulAuth } from "../../auth/authSlice";
import { decodeIdToken } from "../clients/decodeIdToken";

interface Props {
  accessToken: string;
  idToken: string;
}

const getUserInfo = ({ accessToken, idToken }: Props) =>
  new Promise<SuccessfulAuth>((resolve, reject) => {
    const {
      companyName,
      name,
      userImage,
      idTokenError,
      companyId,
      email,
      userId,
      roles,
    } = decodeIdToken(idToken);

    if (idTokenError) {
      reject(idTokenError);
    }

    resolve({
      email,
      accessToken,
      userId: userId as string,
      companyName,
      name,
      userImage,
      roles,
      companyId,
    });
  });
export { getUserInfo };
