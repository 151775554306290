import Axios, { AxiosError } from "axios";
import qs from "qs";
import { hasOwnProperty } from "../utils/hasOwnProperty";
import { externalApi } from "./externalApi";

export type UploadedFile = {
  file: File;
  url: string;
};

export const punctClient = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "comma" }),
});

export const isAxiosError = (error: unknown): error is AxiosError =>
  typeof error === "object" &&
  !!error &&
  hasOwnProperty(error, "isAxiosError") &&
  !!error.isAxiosError;
export const logoutResponseStatuses = [401];

const addAuthorizationBearerToken = (accessToken: string) => {
  punctClient.defaults.headers.authorization = `Bearer ${accessToken}`;
};

const removeAuthorizationBearerToken = () => {
  punctClient.defaults.headers.authorization = null;
};

const punctApi = {
  product: {
    createImageUploadLink: (mimeType: string): Promise<{ url: string }> =>
      punctClient
        .post<{ url: string }>("/ws-catalog/product/image/upload-link", {
          contentType: mimeType,
        })
        .then(({ data }) => data),
  },
  attachments: {
    uploadFile: async (file: File): Promise<UploadedFile> => {
      const uploadUrl = await punctClient
        .get<{ url: string }>(`/ws-order/attachments/upload-url`, {
          params: {
            mimeType: file.type,
          },
        })
        .then((x) => x.data.url);

      await externalApi.uploadFile(file, uploadUrl);

      return { file, url: uploadUrl.split("?")[0] };
    },
    uploadFiles: (files: File[]) =>
      Promise.all(files.map(punctApi.attachments.uploadFile)),
  },
  orders: {
    attachShipmentCertificate: (
      files: FormData,
      orderId: number,
      shipmentId?: number,
    ) => {
      return punctClient
        .post(
          `/pdf/order/${orderId}/shipment/${shipmentId}/certificate`,
          files,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        )
        .then((response) => response);
    },
  },
};

export {
  punctApi,
  addAuthorizationBearerToken,
  removeAuthorizationBearerToken,
};
